import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import CryptoJS from 'crypto-js';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';

const LoginModal = ({ show, onHide }) => {
    const nameDomain = (window.location.host.split('.'));
    const [validated] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [image, setImage] = useState("");
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [loading, setLoading] = useState(true);
    const [passwordType, setPasswordType] = useState("password");
    const [stillSendingOtp, setStillSendingOtp] = useState(false)
    const [dropdwon, setDropdown] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [defaultPartner, setDefaultPartner] = useState(null);
    const [noCustomer, setNoCustomer] = useState(false);
    const [partnerId, setPartnerId] = useState("");
    const [isStatus, setIsStatus] = useState(false);
    const [isActive, setIsActive] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [isMulti, setIsMulti] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [partners, setPartners] = useState([]);
    const [partnersLoaded, setPartnersLoaded] = useState(false);
    const [cmsData, setCmsData] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [resendTimer, setResendTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [otpError, setOtpError] = useState('');
    const inputRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 300);
        return () => clearTimeout(timer);
    }, [showOtpInput]);
    const navigate = useNavigate();
    const submitLockRef = useRef(false);

    let emailForOtpResend;
    let passwordForOtpResend;
    let business_partner_id;
    let customer_id;

    const customer = JSON.parse(window.localStorage.getItem('customer_data'));
    if (customer != undefined) {
        const decryptedString = CryptoJS.AES.decrypt(customer?.data?.securedLogin, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        const decryptedObj = JSON.parse(decryptedString);
        if (customer) {
            if (decryptedObj.newPassword) {
                passwordForOtpResend = decryptedObj.newPassword;
            } else {
                passwordForOtpResend = decryptedObj.password;
            }
            emailForOtpResend = decryptedObj.email;
            business_partner_id = decryptedObj.business_partner_id;
            customer_id = customer.data._id;
        } else {
            emailForOtpResend = null;
            passwordForOtpResend = null;
            business_partner_id = null;
            customer_id = null;
            navigate('/')
        }
    }

    useEffect(() => {
        document.title = "ncentiva || Products";
        setLoading(true);
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        if (res.data?.cms?.brand_logo) {
                            setImage(res.data?.cms?.brand_logo)
                        }
                    } else {
                        setCmsData(true)
                        setImage("https://dev.ncentiva.com/assets/images/logo.png")
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                })
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        };

        const calculateRemainingTime = () => {
            const timestamp = getCookie('customerOtpTiming');
            if (timestamp) {
                const otpTimestamp = parseInt(timestamp, 10);
                const currentTime = Date.now();
                const elapsed = (currentTime - otpTimestamp) / 1000; // in seconds
                const remaining = Math.max(0, 120 - Math.floor(elapsed)); // 120 seconds = 2 minutes
                setResendTimer(remaining);
                setCanResend(remaining <= 0);
            }
        };

        calculateRemainingTime();

        const timer = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timer);
                    setCanResend(true);
                    return 30; // Reset timer to 30 seconds
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [resendTimer]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const togglePassword = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (showOtpInput) {
            handleOtpSubmit();
            return;
        }

        if (submitLockRef.current) return;
        submitLockRef.current = true;
        setIsSubmitting(true);

        if (!email.trim()) {
            toast.warning("Email is required");
            setIsSubmitting(false);
            setTimeout(()=>{
                submitLockRef.current=false
              }, 2000)
            return;
        }
        if (!isValidEmail(email)) {
            toast.error("Invalid email format");
            setIsSubmitting(false);
            setTimeout(()=>{
                submitLockRef.current=false
              }, 2000)
            return;
        }
        if (!password.trim()) {
            toast.warning("Password is required");
            setIsSubmitting(false);
            setTimeout(()=>{
                submitLockRef.current=false
              }, 2000)
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-partners`, {
                email: email
            });

            const status = response.data;
            const customers = response?.data?.partners;

            if (status.status === 'Success') {
                setPartnersLoaded(true);
                setIsMulti(true);
            }
            if (response.data?.status === 'failed') {
                toast.warning(response.data?.message);
            }

            if (customers.length > 1 && status !== "failed" && email !== "" && dropdwon.trim() === "") {
                toast.warning("Please Select Business Partner");
                setIsSubmitting(false);
                setTimeout(()=>{
                    submitLockRef.current=false
                  }, 2000)
                return;
            } else {
                if (isStatus === true && isActive === false) {
                    if (!password && !newPassword) {
                        toast.warning("All Fields Are Required");
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }
                    if (!newPassword) {
                        toast.warning("Please Enter Your New Password");
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }
                    if (!newConfirmPassword) {
                        toast.warning("Please Enter Your Confirm Password");
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }
                    if (newPassword !== newConfirmPassword) {
                        toast.warning("Password And Confirm Password Doesn't Match");
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }
                    if (newPassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
                        toast.warning(
                            <div>
                                <p><b>Password must be</b></p>
                                <br />
                                <ul>
                                    <li>Use at least 8 characters</li>
                                    <li>Use upper and lower case characters</li>
                                    <li>Use 1 or more numbers</li>
                                    <li>Use 1 or more special characters</li>
                                </ul>
                            </div>
                        );
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }
                }

                if (noCustomer) {
                    toast.error("Please Login with your registered email");
                    setIsSubmitting(false);
                    setTimeout(()=>{
                        submitLockRef.current=false
                      }, 2000)
                    return;
                }

                try {
                    if (dropdwon.trim() === "" && partners.length > 1) {
                        toast.warning("Please Select Business Partner");
                        setIsSubmitting(false);
                        setTimeout(()=>{
                            submitLockRef.current=false
                          }, 2000)
                        return;
                    }

                    const credentials = {
                        email: email,
                        password: password,
                        business_partner_id: dropdwon,
                        type: 'customer',
                        newPassword: newPassword,
                        newConfirmPassword: newConfirmPassword,
                        timestamp: Date.now()
                    };
                    const jsonString = JSON.stringify(credentials);
                    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/otp`, { securedLogin });

                    if (data.message === 'All Fields are Required') {
                        toast.warning(data.message);
                    } else if (data.message === 'Email Required') {
                        toast.warning(data.message);
                    } else if (data.message === 'Password Required') {
                        toast.warning(data.message);
                    } else if (data.message === 'Email or Password is not Valid') {
                        toast.warning(data.message);
                    } else if (data.status === "failed") {
                        toast.error(data.message);
                    } else if (data.message === 'This Account is Deactivated') {
                        toast.warning(data.message);
                    }

                    if (data.status === "success") {
                        setNewPassword("");
                        setNewConfirmPassword("");
                        window.localStorage.setItem("customer_data", JSON.stringify(data));
                        localStorage.removeItem('customer_email');
                        setShowOtpInput(true);
                        setStillSendingOtp(false);
                    } else if (data.status === "failed") {
                        setTimeout(() => setStillSendingOtp(false), 2000);
                    }
                } catch (err) {
                    console.log(`Error: ${err}`);
                    toast.error(err?.response?.data?.message || 'An error occurred');
                }
                finally {
                    setIsSubmitting(false);
                    setTimeout(()=>{
                        submitLockRef.current=false
                      }, 2000)
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setIsStatus(false);
            setIsMulti(false);
            setPartnerId("");
            setIsSubmitting(false);
            setTimeout(()=>{
                submitLockRef.current=false
              }, 2000)
        }
    }

    const handleOtpSubmit = async () => {
        if (!otp.trim()) {
            toast.warning("OTP is required");
            return;
        }

        if (otp.length !== 6) {
            toast.error("OTP must be 6 digits");
            return;
        }

        setIsSubmitting(true);

        try {
            const customer = JSON.parse(window.localStorage.getItem('customer_data'));
            if (customer) {
                const decryptedString = CryptoJS.AES.decrypt(customer?.data?.securedLogin, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
                const decryptedObj = JSON.parse(decryptedString);
                
                const credentials = {
                    email: decryptedObj.email,
                    password: decryptedObj.newPassword || decryptedObj.password,
                    otp: otp,
                    business_partner_id: decryptedObj.business_partner_id,
                    customer_id: customer.data._id
                };
                const jsonString = JSON.stringify(credentials);
                const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/login/verify`, {
                    securedLogin
                });

                if (data.status === "success") {
                    toast.success(data.message);
                    localStorage.removeItem('customer_email');
                    const url = data.brand_url || `${process.env.REACT_APP_URL}`;

                    const subdomain = url.split('//')[1].split('.')[0];

                    if (subdomain !== "localhost:3000/" || subdomain !== "dev") {

                        const serializedData = JSON.stringify(data);
                        window.localStorage.setItem('customer', serializedData);
                        setTimeout(() => {
                            localStorage.removeItem('customer_data');
                            window.location.href = `${url}?data=${encodeURIComponent(serializedData)}`;
                        }, 0);

                        setTimeout(() => {
                            const queryParams = new URLSearchParams(window.location.search);
                            const getserializedData = queryParams.get('data');
    
                            if (getserializedData) {
                                try {
                                    const data = JSON.parse(decodeURIComponent(getserializedData));
                                    window.localStorage.setItem('customer', JSON.stringify(data));
                                    setTimeout(() => {
                                        navigate('/');
                                    }, 0);
                                } catch (error) {
                                    console.error('Error parsing or decoding data:', error);
                                }
                            }
                        }, 1000);
                       
                   } else {
                    if (nameDomain[0] !== "localhost:3000" || nameDomain[0] !== "dev" || nameDomain[0] !== "uat") {
                        const serializedData = JSON.stringify(data);
                        localStorage.removeItem('customer_data');
                        window.location.href = `${url}?data=${encodeURIComponent(serializedData)}`;

                    } else {
                        
                        window.localStorage.setItem('customer', JSON.stringify(data));
                        setTimeout(() => {
                            onHide()
                            window.location.reload();
                        }, 1000);
                    }
                   }

                    // Use window.location.replace instead of window.location.href
                    // window.location.replace(`${url}?data=${encodeURIComponent(serializedData)}`);
                } else {
                    toast.error(data.message || "Invalid OTP. Please try again.");
                }
            } else {
                toast.error("Customer data not found. Please try logging in again.");
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error(error?.response?.data);
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleOtpChange = (e) => {
        let trimValue = e.target.value.replace(/\D/g, '');
        setOtp(trimValue);

        if (trimValue.length !== 6 && trimValue.length > 0) {
            setOtpError('OTP must be 6 digits');
        } else {
            setOtpError('');
        }
    };

    const otpSend = async (e) => {
        e.preventDefault();
        if (!stillSendingOtp) {
            try {
                setStillSendingOtp(true);
                const credentials = {
                    email: emailForOtpResend,
                    password: passwordForOtpResend,
                    business_partner_id: business_partner_id,
                    customer_id: customer_id,
                    otppath: false,
                    timestamp: Date.now()
                };
                console.log(credentials, "hghgh");
                const jsonString = JSON.stringify(credentials);
                const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                const customer = JSON.parse(window.localStorage.getItem('customer_data'));
                if (customer) {
                    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/otppage/`, {
                        securedLogin
                    });
                    if (data.status === "success") {
                        toast.success("OTP sent successfully!");
                        setStillSendingOtp(false);
                        // setCanResend(false);
                        // document.cookie = `customerOtpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                        // setResendTimer(120); // Set timer to 120 seconds (2 minutes)
                    } else {
                        if (data.message === "Please wait before requesting another OTP.") {
                            setCanResend(false);
                            setStillSendingOtp(false);
                            document.cookie = `customerOtpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                            setResendTimer(120);
                        }
                        toast.warning(data.message);
                    }
                } else {
                    toast.error("Customer data not found. Please try logging in again.");
                }
            } catch (err) {
                console.log(`Error : ${err}`);
                toast.error(err.response?.data || "An error occurred while sending OTP");
            } finally {
                setTimeout(() => setStillSendingOtp(false), 3500);
            }
        }
    };

    const handleBusinessPartner = async (selectedOption) => {
        const dropdownvalue = selectedOption.target.value
        if (dropdownvalue === "") {
            setDropdown("");
            setPartnerId("")
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
                    email: email,
                    partner_id: ""
                });
                setIsStatus(response.data.data);
                setIsActive(response.data.activity);
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setDropdown(dropdownvalue);
            setPartnerId(selectedOption.target.value)
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
                    email: email,
                    partner_id: selectedOption.target.value
                });
                setIsStatus(response.data.data);
                setIsActive(response.data.activity);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleKeyUp = async (event) => {
        event.preventDefault();
        if (event.key === 'Enter') {
            submitLockRef.current = false;
        }
        if (!email) {
            setIsEmail(false);
            setPartners([]);
            setPartnerId("")
        } else {
            setIsEmail(true)
        }
        if (email && isValidEmail(email)) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-partners`, {
                    email: email
                });

                const status = response.data;
                const partner = response?.data?.partners;

                if (status.status === 'Success') {
                    setPartners(partner);
                    setPartnersLoaded(true);
                    setDefaultPartner(partners[0]);
                    setIsMulti(true);
                } else {
                    if (response.data.message === "Customer Doesn't Exist") {
                        setIsStatus(false);
                        setIsMulti(false);
                        setPartnerId("")
                        setPartners([]);
                    }
                    console.error('Error fetching partners:', response.data.message);
                    setIsStatus(false);
                    setIsMulti(false);
                    setPartnerId("")
                }

                if (status === "failed") {
                    setNoCustomer(true);
                    setIsStatus(false);
                    setIsMulti(false);
                    setPartnerId("")
                } else {
                    setNoCustomer(false);
                }
            }  catch (error) {
                console.error('Error:', error);
                setIsStatus(false);
                setIsMulti(false);
                setPartnerId("")
            }
            if (password && password?.length > 7) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
                        email: email,
                        partner_id: partnerId
                    });
                    setIsStatus(response.data.data);
                    setIsActive(response.data.activity);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
    };

    const checkStatus = async (event) => {
        event.preventDefault();
        if (email && isValidEmail(email)) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
                    email: email,
                    partner_id: partnerId
                });
                setIsStatus(response.data.data);
                setIsActive(response.data.activity);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    function checkWhitespace(event) {
        event.preventDefault();
        var data = event.clipboardData.getData("text/plain");
        var trimmedData = data.trim();
        if (trimmedData.length > 0) {
            document.execCommand('insertText', false, trimmedData);
        }
    }

    function keyDown(e) {
        var e = window.event || e;
        var key = e.keyCode;
        if (key == 32) { //space
            e.preventDefault();
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default form submission
            if (!submitLockRef.current) {
                handleSubmit(e);
            }
        }
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered backdrop={showOtpInput ? "static" : "false"} keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{showOtpInput ? 'Enter OTP' : 'Login'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=' d-flex justify-content-center text-center align-items-center'>
                    <img
                        src={image}
                        className="imgLogo img-fluid img_hover"
                        alt=""
                        style={{
                            width: '200px',
                            height: '50px',
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                <Form className="form px-4 pt-3" noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} >
                    {!showOtpInput ? (
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-dark">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter Email"
                                    onInput={handleKeyUp}
                                    onKeyUp={handleKeyUp}
                                    onKeyPress={(e) => keyDown(e)}
                                    onPaste={(e) => checkWhitespace(e)}
                                    value={email}
                                />
                            </Form.Group>
                            {partners?.length > 1 ? (
                                <Form.Group className="mb-3" controlId="formBasicPartner">
                                    <Form.Label className="text-dark">Select Your Account</Form.Label>
                                    <div
                                        className="position-relative"
                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}
                                        onClick={toggleDropdown}
                                    >
                                        <Form.Control
                                            as="select"
                                            onChange={handleBusinessPartner}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <option value="">Select Your Account</option>
                                            {partners.map((partner, index) => (
                                                <option key={index} value={partner.value}>
                                                    {partner.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <span
                                            className="position-absolute"
                                            style={{
                                                right: '12px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                zIndex: 1,
                                                width: '24px',
                                                height: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={(e) => {
                                                toggleDropdown();
                                            }}
                                        >
                                            {isDropdownOpen ? <MdOutlineKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                        </span>
                                    </div>
                                </Form.Group>
                            ) : ""}
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="text-dark">Password</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        type={passwordType}
                                        onChange={(e) => {
                                            let trimmedValue = e.target.value;
                                            trimmedValue = trimmedValue.replace(/\s/g, '')
                                            setPassword(trimmedValue)
                                        }}
                                        value={password}
                                        name="password"
                                        onKeyUp={checkStatus}
                                        placeholder="Enter Password"
                                        onPaste={(e) => checkWhitespace(e)}
                                    />
                                    <button
                                        type="button"
                                        className="bg-transparent border-0 position-absolute eyBtn"
                                        onClick={togglePassword}
                                    >
                                        {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                            </Form.Group>
                            {isStatus && isMulti === true && isActive !== true && isEmail && password.length > 0 ? (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-dark">New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                onChange={(e) => {
                                                    let trimValue = e.target.value;
                                                    trimValue = trimValue.replace(/\s/g, '')
                                                    setNewPassword(trimValue)
                                                }}
                                                placeholder="Enter New Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newPassword}
                                            />
                                            {newPassword.length > 0 && (
                                                <button
                                                    type="button"
                                                    className="bg-transparent border-0 position-absolute eyBtn"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-dark">Confirm New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmNewPassword ? 'text' : 'password'}
                                                onChange={(e) => {
                                                    let trimmedValue = e.target.value;
                                                    trimmedValue = trimmedValue.replace(/\s/g, '')
                                                    setNewConfirmPassword(trimmedValue)
                                                }}
                                                placeholder="Enter Confirm New Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newConfirmPassword}
                                            />
                                            {newConfirmPassword.length > 0 && (
                                                <button
                                                    type="button"
                                                    className="bg-transparent border-0 position-absolute eyBtn"
                                                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                >
                                                    {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                            )}
                                        </div>
                                    </Form.Group>
                                </>
                            ) : ""}
                            <Form.Group className="mb-3">
                                <Link
                                    className="text-end font14 text-primary fw-medium"
                                    to={`/customer-send-password-reset-email`}
                                >
                                    Forgot Password ?
                                </Link>
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group className="mb-3" controlId="formBasicOtp">
                            <Form.Label className="text-dark">Enter OTP</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                onChange={handleOtpChange}
                                placeholder="Enter OTP"
                                value={otp}
                                maxLength={6}
                                ref={inputRef}
                            />
                            {otpError && <div className="text-danger mt-2">{otpError}</div>}
                            <p className="font14 mt-2 text-dark">
                                OTP not received?{" "}
                                {canResend ? (
                                    <>
                                        <span
                                            className="fw-semibold resend-link"
                                            style={{ cursor: 'pointer' }}
                                            onClick={otpSend}
                                        >
                                            Click here
                                        </span>
                                        <span className="font14 mt-2 text-dark"> to send it again.</span>
                                    </>
                                ) : (
                                    <span className="fw-semibold text-danger resend-timer">
                                        Resend in {resendTimer}s
                                    </span>
                                )}
                            </p>
                        </Form.Group>

                    )}
                    <Form.Group className="mb-3 d-flex justify-content-center">
                        <Button
                            type="submit"
                            variant="primary"
                            style={cmsData
                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                            }
                            className="w-50 fullRounded font12 fw-bold py-3 shadowPrimary button_hover"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Please wait....' : (showOtpInput ? 'Verify OTP' : 'Login')}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;