
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
// import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import axios from "axios";
import CustomerForm from "./CustomerForm";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaSortUp, FaSortDown, FaRecycle } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import styles from "./customers.module.css"
import { getNewToken } from "../config/cookie.config";
// import Pagination from 'react-bootstrap/Pagination';
// import '../components/elements/pagination.css'


const Customers = () => {

  useEffect(()=>{
    const adminCheck= window.location.pathname;
    if(adminCheck.includes('admin')){ 
    document.title = "ncentiva || Admin Customer"
    }
},[])

  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }

  const [role] = useState(userrole);
  const [userid] = useState(usersid);
  const [customerData, setCustomerData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [partnerorder, setPartnerorder] = useState("ASC");
  const [fundorder, setFundorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [csvData, setCsvData] = useState([]);
  const [showCsvModal, setshowCsvModal] = useState(false);
  const [showReduceModal, setshowReduceModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [search, setSearch] = useState("");
  const shouldLog = useRef(true);
  const [statusorder, setStatusorder] = useState("ASC");
  const [sort, setSort] = useState("");
  const [showPage, setShowPage] = useState(1);
  const [noData, setNoData] = useState(false);
  const [disable, setDisable] = useState(false);
  const [clicked, setClicked] = useState(true);
  const [previewData, setPreviewData] = useState();
  const [previewData1, setPreviewData1] = useState();

  const [reduces, setReduces] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showConfirmModal,setShowConfirmModal] = useState(false);

  const [busName,setBusName] = useState('');
  const [cusName,setCusName]= useState('');
  const [cusEmail,setCusEmail] = useState('');
  const [currentFund,setCurrentFund] = useState('');
  const [addedFund,setAddedFund] = useState('');
  const [reducedFund,setReducedFund] = useState('');
  const [newFund,setNewFund] = useState('');
  const [checkFundStatus,setCheckFundStatus] = useState(false)
  const [fundValidate,setFundValidate] = useState(false)
  const handleRowsPerPageChange = (event) => {
    pagenumberSearch(1)
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getAllCustomers(pageNumber, selectedRowsPerPage);
  };

  const handleClosehandleCsvModal = () => {
    setshowCsvModal(!showCsvModal);
    setFilename('');
    setLoadingCsv(false);
  };
  const handleCloseReduceModal = () => {
    setFilename(''); 
    setshowReduceModal(!showReduceModal);
    setStartPartnerClick(false)
    setStartCustomerClick(false)
    setFundValue("");
    setFundValidate(true)
    setselectedPartnerValidate('default');
    setselectedCustomerValidate('default');
    setIsChecked(true);
    setReduces(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setshowReduceModal(true);
    setBusName("");
    setCusName("");
    setCusEmail("");
    setCurrentFund("");
    setNewFund("");
    setAddedFund("");
    setReducedFund("");
    setshowReduceModal(false);
    setStartPartnerClick(false)
    setStartCustomerClick(false)
    setFundValue("");
    setFundValidate(true);
    setselectedPartnerValidate('default');
    setselectedCustomerValidate('default');
    setIsChecked(true);
    setReduces(true);
  };

  const doubleConfirmation = () =>{
    if(allValidation !== 1 )
    {
      setStartPartnerClick(true);
      setStartCustomerClick(true);
      setStartFundValidation(true);

    }else if(fundValue.length === 0){
     toast.warning("Fund Value Can Not Empty")
     return false;
    }else if (parseFloat(fundValue) > parseFloat(customerAmount) && (isChecked)){
      return false;
    }else{  
     const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/confiramtion/modifyfunds`;
      // Data to send in the POST request body
      const data = {
        business_partner: sendPartnerID,
        customer_email: customerEmail,
        reduce_funds: isChecked,
        funds: fundValue,
        // payment_type:
      };

      const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        token = user.token;
      } else {
        token = null;
      }

      axios.post(apiUrl, data, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      })
      .then((response)=>{

        if(response.data.status === "failed"){
          toast.warning(response.data.message)
        }
        if(response.data.status === "success"){
          setShowConfirmModal(true);
          setshowReduceModal(false);
        }
        const customerData = response.data.customer;
        setBusName(customerData.business_partner);
        setCusName(`${customerData.first_name} ${customerData.last_name}`);
        setCusEmail(customerData.email);
        setCurrentFund(response.data.currentFunds);
        setNewFund(response.data.modifiedFunds);
        setCheckFundStatus(response.data.deduct)
        setAddedFund(response.data.fund);
        setReducedFund(response.data.fund);

      })
      .catch((err)=>{
       console.log(err);
      })

    }

  }


  const [isLoading, setIsLoading] = useState(true);

  // sorting

  // name

  //for sort option
  const options = [
    // {label: "view: all", value: "view: all" },
    { value: "Price Low - High", label: "Price Low - High" },
    { value: "Price High - Low", label: "Price High - Low" },
    { value: "All Brands", label: "All Brands" },
    { value: "Most value", label: "Most value" },
    { value: "Order A-Z", label: "Order A-Z" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "var(--darkblue)" : "var(--color)",
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "auto",
      display: "flex",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "var(--color)";
      const fontSize = "14px";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color, fontSize };
    },
  };
  // const [getbusinessPartner, setbusinessPartner] = useState([]);
  const [getbusinessPartnerss, setbusinessPartners] = useState([]);
  const [selectedPartnerValidate, setselectedPartnerValidate] = useState('default');
  const [selectedCustomerValidate, setselectedCustomerValidate] = useState('default');

  const getbusinessPartners = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallcustomersbrand`)
      .then((response) => {
        const businessFilter = response.data.businessFilter
        // const customers = response.data.customersbrand;
        // const uniqueIds = [];
        // const unique = customers.filter(element => {
        //   const isDuplicate = uniqueIds.includes(element.value);
        //   if (!isDuplicate) {
        //     uniqueIds.push(element.value);
        //     return uniqueIds;
        //   }
        //   return false;
        // });
        // setbusinessPartner(unique);

        // const customerss = response.data.customersbrands;
        // const uniqueIdss = [];
        // const uniques = customerss.filter(element => {
        //   const isDuplicate = uniqueIdss.includes(element.value);
        //   if (!isDuplicate) {
        //     uniqueIdss.push(element.value);
        //     return uniqueIdss;
        //   }
        //   return false;
        // });
        setbusinessPartners(businessFilter);

      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const [getbusinessCustomer, setbusinessCustomer] = useState([]);
  // const [businessEnable, setbusinessEnable] = useState(false);
  const [sendPartnerID, setSendPartnerID] = useState('');
  const [startPartnerClick, setStartPartnerClick] = useState(false);
  const [startCustomerClick, setStartCustomerClick] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const sendPartner = (partnerId) => {
    // console.log(partnerId);
    // const apiUrl = `https://backend.payouts.ncentiva.com/api/admin/customerpartner/${partnerId}`;
    setSendPartnerID(partnerId)

    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/business-customer/${partnerId}`, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }

      }
      )
      .then((response) => {
        const businessCustomer = response.data.customers
        setbusinessCustomer(businessCustomer);
        // setbusinessEnable(true);


      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const [firstTime, setFirstTime] = useState(false);
  const handlePartnerSelection = (event) => {
    const selectedPartnerId = event.target.value;
    sendPartner(selectedPartnerId);
    setselectedPartnerValidate(selectedPartnerId);

    // Reset customer selection and set default values when a new partner is selected
    if (selectedPartnerId !== 'default') {

      setselectedCustomerValidate('default');

      const selectedCustomerId = 'default';
      setselectedCustomerValidate(selectedCustomerId);
    } else {

      // Handle default case here or set specific default values
      setselectedCustomerValidate('default');
      setCustomerName('');
      setCustomerLastName('');
      setCustomerEmail('');
      setCustomerPhone('');
      setCustomerAmount('');
    }
  };
  const handleCustomerSelection = (event) => {
    // sendCustomer('default');
    const selectedCustomerId = event.target.value;
    setselectedCustomerValidate(selectedCustomerId);
    if (selectedCustomerId !== 'default') {
      sendCustomer(selectedCustomerId);
    }
    else {
      setCustomerName('');
      setCustomerLastName('');
      setCustomerEmail('');
      setCustomerPhone('');
      setCustomerAmount('');
    }
  };


  const formatDate = (dateString) => {
    // Convert the date string to a Date object
    const utcDate = new Date(dateString);
  
    // Convert the UTC date to the EST time zone
    const estOffset = -5 * 60; // Eastern Standard Time (EST) offset is -5 hours
    const estTimestamp = utcDate.getTime() + estOffset * 60 * 1000;
    const estDate = new Date(estTimestamp);
  
    // Format the date in the desired format
    const estOptions = {
      timeZoneName: "short",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
  
    return estDate.toLocaleDateString("en-US", estOptions);
  };


  const PartnerClick = () => {
    setStartPartnerClick(true);
  }
  const CustomerClick = () => {
    setStartCustomerClick(true);
  }
  const [filename, setFilename] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerAmount, setCustomerAmount] = useState('');
  const [previewModal, setPreviewModal] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const sendCustomer = (customerId) => {

    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcustomer/${customerId}`, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }

      }
      )
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('client');
          navigate('/admin');
          toast.error("Session Expired Please Login Again")
        }

        const customerData = response.data.customer
        setCustomerName(customerData.first_name)
        setCustomerLastName(customerData.last_name)
        setCustomerEmail(customerData.email)
        setCustomerPhone(customerData.phone)
        setCustomerAmount(customerData.balance)

      })
      .catch((error) => console.error(`Error: ${error}`));

  }



  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleDeleteUser = (userId) => {
    setSelectedUserId(userId);
    setShowModal(true);
  };

  const handleConfirmDelete =  () => {
    if (selectedUserId) {
        const user = JSON.parse(window.localStorage.getItem("client"));
        let token = null;
        
        if (user) {
          token = user.token;
        }
  
       axios.delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletecustomer/${selectedUserId}`,
          {
            data: { loginUserId: usersid },
            headers: {
              Authorization: `Bearer ${getNewToken()}`,
            },
          }
        ).then((response) => {
          toast.success(response.data.message);
          getAllCustomers(pageNumber, rowsPerPage);
          setShowModal(false);
        })
        .catch((error) => {
          setShowModal(false);
          toast.error("Failed to delete customer");
          console.error(`Error: ${error}`)
        });
  
      }
  };
  
  
  const handleModalShow = (id) => {
    setShowModal(true); 
  };
  const handleCloseModal = () => {
    setShowModal(false); 
  };





  const [isChecked, setIsChecked] = useState(true); // Assuming "Reduce funds" is the default option

  const reduceRadioChange = () => {

    setIsChecked(true);
    setReduces(true);
  };
  const addRadioChange = () => {
    setIsChecked(false);
    setReduces(false);
  };

  const [fundValue, setFundValue] = useState('');
  // const [fundValidation, setFundValidation] = useState(false);
  const [startFundValidation, setStartFundValidation] = useState(false);

  // const handleFundChange = (event) => {
  //   setFundValue(event.target.value);
  // };
  const [allValidation, setAllValidation] = useState(0);
  const handleFundChange = (event) => {
    let inputValue = event.target.value.replace(/[eE-]/g, '');
    const parts = inputValue.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2);
      inputValue = parts[0] + (decimalPart.length > 0 ? '.' + decimalPart : '');
    }
  
    setFundValue(inputValue);
    setStartFundValidation(true);
  
    if (selectedPartnerValidate !== 'default' && selectedCustomerValidate !== 'default' && inputValue.length !== 0) {
      setAllValidation(1);
    } else {
      setAllValidation(0);
    }
  };
  
  const handleKeyDown = (event) => {
    // Prevent the user from typing "e"
    if (event.key === "e" || event.key === "E" || event.key === "-") {
      event.preventDefault();
    }
    setStartFundValidation(true);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const balanceSubmit = () => {
    
    
      if (!isSubmitting) {
        setIsSubmitting(true);
        const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/modifyfunds`;
        // Data to send in the POST request body
        const data = {
          business_partner: sendPartnerID,
          customer_email: customerEmail,
          reduce_funds: isChecked,
          funds: fundValue,
          loginUserId: userIds
          // payment_type:
        };

        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
          token = user.token;
        } else {
          token = null;
        }

        axios.post(apiUrl, data, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        })
          .then(response => {
            // Handle the API response here (if needed)
            if (response.data.customer) {
              toast.success(response.data.message);
              setshowReduceModal(false);
              setShowConfirmModal(false);
              // getAllCustomers()
              setIsChecked(true)
              // window.location.reload();
              if (response.data.message) {
                setTimeout(() => {
                  setIsSubmitting(false);
                  window.location.reload();
                }, 1000);
              }

            } else {
              // toast.warning(response.data.message);
              setIsSubmitting(false);

            }
          })
          .catch(error => {
            console.error('API Error:', error);
          });
      }
    

  };

  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  // email
  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  // partner

  const partnersorting = (partnerorder) => {
    if (partnerorder === "DSC") {
      setSort("dscPartner");

      setPartnerorder("DSC");
    } else {
      setSort("ascPartner");

      setPartnerorder("ASC");
    }
  };

  // partner

  // availablefund
  const fundsorting = (fundorder) => {
    if (fundorder === "DSC") {
      setSort("dscFund");

      setFundorder("DSC");
    } else {
      setSort("ascFund");

      setFundorder("ASC");
    }
  };


  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };

  const statusSorting = (statusOrder) => {
    if (statusOrder === "DSC") {
      setSort("dscStatus");

      setStatusorder("DSC");
    } else {
      setSort("ascStatus");

      setStatusorder("ASC");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllCustomers(pageNumber, rowsPerPage);
      redirectToLogin();
      getbusinessPartners();
      setIsLoading(false);
    }, 600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const handleChange = (selectedOption) => {
    if (selectedOption.value === "All Partners") {
      setSearch("");
    } else {
      setSearch(selectedOption.value);
    }
  };

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };

  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  if (user) {
    token = user.token;
  } else {
    token = null;
  }




  const getAllCustomers = (pageNumber, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 10
    }
    setIsLoading(true);
    setNoData(false);
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customers?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}`, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }

      }
      )
      .then((response) => {
      setIsLoading(false);
        if (response.data.message === "Please Login") {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          toast.warning("Session Expired Please Login Again")
          // window.location.reload();
        }
        const allUsers = response.data.customers;
        if (allUsers.length < 1) {
          if(pageNumber > 1){
            var d=pageNumber-1
            
          //  setPageNumber(d)
          }else{
            setNoData(true);
          }
        }
        else {
          setNoData(false);
          const totalPages = response.data.totalPages;
          setCustomerData(allUsers);
          setNumberOfPages(totalPages);
        }
      })
      .catch((error) => {
      setIsLoading(false);
        console.error(`Error: ${error}`)
        if (error.response.data.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
      });
  };



  const deleteCustomer = (id) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .put(

        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customer/soft-delete`,
        {
          soft_delete: true,
          type: "id",
          id: id,
          status:false
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
      )
      .then((response) => {
        toast.success("Customer Move to Trash");
        getAllCustomers(pageNumber, rowsPerPage);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  function handleStatus(id, status, email, business_partner_id) {
    if (status === false) {
      status = true;
    } else {
      status = false;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customer/status`, {
        id,
        status,
        email,
        business_partner_id,
        loginUserId: usersid
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        if (response.data.status == 'success') {
          toast.success(response.data.message);
        } else if (response.data.message === "Not enough funds to activate account") {
          // toast(response.data.message);
          toast.warning(
            <div>
              <p><b>Insufficient funds !</b></p><br />
              <p>Please contact your business partner</p>
            </div>
          )
        } else if (response.data.message === "Please provide balance in business partner account") {
          toast.warning(response.data.message);
        } else if ( response.data.message === "Your business partner is not exist" ) {
          toast.warning(response.data.message);
        }
        else if (response.data.status == 'failed') {
          let text = "Please select a bussiness partner";
          if (window.confirm(text) == true) {
            navigate(`/admin/customers/${id}/edit`)
          } else {
            navigate('/admin/customers')
            // toast("Wrong business partner");
          }
        }

        getAllCustomers(pageNumber, rowsPerPage);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
        toast.error(error?.response?.data?.message ?? "Internal Server Error");
      });
  }

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
        {number + 1}
      </li>,
    );
  }






  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
      setShowPage(pageNumber + 2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }


  // pagination

  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const setCustomers = (userDataInString) => {
    setLoadingCsv(true)
    var userDataInJSON = [];
    var rows = userDataInString.split("\n");
    var er = rows[0].search("\r");
    if (er > 1) {
      rows[0] = rows[0].slice(0, -1);
    } else {
      rows[0] = rows[0].slice(0);
    }
    var fields = rows[0].split(",");
    if(fields[0] !== "first_name") {
      toast.warning("First Name Is Required");
      setLoadingCsv(false);
      return false;
    }
    if(fields[2] !== "email") {
      toast.warning("Email Is Required");
      setLoadingCsv(false);
      return false;
    }
    if(fields[5] !== "balance") {
      toast.warning("Balance Is Required");
      setLoadingCsv(false);
      return false;
    }
    if(fields[6] !== "business_partner") {
      toast.warning("Business Partner Is Required");
      setLoadingCsv(false);
      return false;
    }
    if (fields[0] == "first_name" && fields[1] == "last_name" && fields[2] == "email" && fields[3] == "code" && fields[4] == "phone" && fields[5] == "balance" && fields[6] == "business_partner") {

      for (var i = 1; i < rows.length; i++) {
        // To check null fields
        var flag = false;

        // Creating individual JSON objects from string
        var singleUserObject = {};

        // To handle the extra space in the end
        var ers = rows[i].search("\r");
        if (ers >= 0) {
          // rows[i] = rows[i].slice(0);
          rows[i] = rows[i].slice(0, -1);

        } else {
          rows[i] = rows[i].slice(0);
          //  rows[i] = rows[i].slice(0, -1);
        }

        // Extracting the data for the objects
        var singleRow = rows[i].split(",");
        if (fields.length !== singleRow.length && singleRow.length > 1) {
          toast.warning("There is some issue in csv file format.");
          setLoadingCsv(false);
          return false;
        }
        for (var j = 0; j < fields.length; j++) {
          if (fields[j] === "last_name" && singleRow[j] === "") {
            singleRow[j] = " "; 
          }
          if (singleRow[j] === "") {
            flag = true;
          }

          // Key-Value Pairs
          singleUserObject[fields[j]] = singleRow[j];
          singleUserObject["user_id"] = userid;
          singleUserObject["password"] = "cust@1234";
          singleUserObject["status"] = 1;
        }



        if (flag) {
          // To be added to Invalid Users
        } else {
          // To be sent to backend
          userDataInJSON.push(singleUserObject);
        }
      }

      setCsvData(userDataInJSON);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/csv-preview`,
          userDataInJSON, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        },
        )
        .then((res) => {

          if (res.data.status === "failed") {
            toast.warning(res.data.message);
            setLoadingCsv(false)
            return false;
          }else {
            // toast.success(res.data.message);
            setPreviewData(res.data.customers);
            setPreviewData1(res.data);
            setBusinessName(res.data.businessName)
            setLoadingCsv(true)
            setTimeout(() => {
              setLoadingCsv(false)
              setPreviewModal(true)
            }, 800);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingCsv(false)
          // if (err.response.data.msg) {
          //   toast.error(err.response.data.msg);
          // }
        });


    } else {
      toast.warning("Invalid CSV File")
      setCsvData([]);
      setLoadingCsv(false);

    }


  };

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    }
    else {
      setPageNumber(0);
      setShowPage(pages);
    }
  }



  const handleSubmit = async (e) => {
    setLoadingCsv(true);
    e.preventDefault();
    setDisable(true)
    setTimeout(() => {
      setDisable(false);
    }, 2000);
    if (csvData) {
      if (csvData.length === 0) {
        toast.warning("Please Upload A CSV File");
        setTimeout(() => {
          setLoadingCsv(false);
        }, 1000);
        return false;
      }

    }

    for (var i = 0; i < csvData.length; i++) {

      if(csvData[i].phone){
        if (csvData[i].phone.length < 9) {
          toast.warning("Phone number must be 10 digits");
          setLoadingCsv(false);
          return false;
        }


       

      }
      if (csvData[i].balance) {

        var balanceLength = csvData[i].balance;
        balanceLength = balanceLength?.replace(/^\s+|\s+$/gm, '')

      }


      let business_partner = csvData[i].business_partner;
      if (csvData[i].business_partner) {
        business_partner = business_partner?.replace(/^\s+|\s+$/gm, '')
      }

      if (balanceLength <= 0.999) {
        toast.warning("Please Enter A Balance Atleast 1");
        setLoadingCsv(false);
        return false;
      }

      //  if(balanceLength?.length<=2 ){
      //   toast("Minimum 3 Digits Required For Balance Amount")
      //   return false; 
      //  }

      //  if(balanceLength?.length>11){
      //   toast.warning("Maximum 10 Digits Allowed for Balance Amount")
      //   return false; 
      //  }

      //  business_partner 
      if (business_partner?.length <= 11) {
        toast.warning("Please Enter A Business Partner");
        setLoadingCsv(false);
        return false;
      }

      //  business_partner 


      let codes = csvData[i].code;
      if (csvData[i].code) {
        codes = codes?.replace(/^\s+|\s+$/gm, '')
        if (codes?.length < 1) {
          if (codes?.length > 4)
            toast.warning("Please Enter A Code Valid Code");
            setLoadingCsv(false);
          return false;
        }

      }



      let text = csvData[i].email;
      if (csvData[i].email) {
        let emailValue = text.trim();

        // Use the regex for strict email validation with specific allowed TLDs
        //const allowedTLDs = /\.(com|org|in|xyz)$/i;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

        if (!emailRegex.test(emailValue)) {
          toast.warning("Invalid Email");
          setLoadingCsv(false);
          return false;
        }
        text = text.replace(/^\s+|\s+$/gm, '')
        if (text.length <= 0) {
          toast.warning("Please Enter Email Address");
          setLoadingCsv(false);
          return false;
        }

      }

      let firstName = csvData[i].first_name;
      if (csvData[i].first_name) {
        firstName = firstName.replace(/^\s+|\s+$/gm, '')

      }

      if (csvData[i].first_name.length === 0) {
        toast.warning("Please enter First Name");
        setLoadingCsv(false);
        return false;
      }


      let lastName = csvData[i].last_name;
      if (csvData[i].last_name) {
        lastName = lastName?.replace(/^\s+|\s+$/gm, '')

      }

      if (firstName && firstName.length <= 0) {
        toast.warning("Please enter First name");
        setLoadingCsv(false);
        return false;
      }
      if (firstName && firstName.length <= 2) {
        toast.warning("First Name Too Short Atleast 2 characters");
        setLoadingCsv(false);
        return false;
      }

      if (!text) {
        toast.warning("Please enter Email");
        setLoadingCsv(false);
        return false;
      }



      if (!balanceLength) {
        toast.warning("Please enter Balance");
        return false;
      }
      if (business_partner.includes("NCT-B-XXXXXX") || business_partner.includes("X")) {
        toast.warning("Please enter Business partner");
        return false;
      }
      if (!business_partner) {
        toast.warning("Please enter Business partner");
        return false;
      }




      var emailRegex = /^[a-zA-Z0-9.!#$%&'+^_~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;;

      if (!emailRegex.test(text)) {
        toast.warning("Please enter a valid email address");
        return false;
      }

    }
    setLoadingCsv(true)
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customer/csv`,
        csvData, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },
      )
      .then((res) => {
        if (res.data.status === "failed") {
          toast.warning(res.data.message);
          setLoadingCsv(false)
          return false;
        }
        setPreviewModal(false)
        setTimeout(() => {
          getAllCustomers();
          setLoadingCsv(false)
          handleClosehandleCsvModal();
          handleClearData(); 
          window.location.reload();
          getAllCustomers();
          // navigate("/admin/customers");
        }, 1000);
        toast.success(res.data.message);
        // setLoadingCsv(false);
        // setPreviewData(res.data.customer);
      })
      .catch((err) => {
        navigate("/admin/customers");
        console.log(err);
        if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
        setLoadingCsv(false)
         }

      });
  };
  const softDelete = async (e) => {
    const { name, checked, value } = e.target;
    if (name === "allSelect") {
      let tempUser = customerData.map((user) => {
        return { ...user, isChecked: checked };
      });
      setCustomerData(tempUser);
      localStorage.setItem("customer_Delete", "All");

    } else {
      localStorage.setItem("customer_Delete", "id");
      let tempUser = customerData.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      setCustomerData(tempUser);
      const user = tempUser.filter((e) => e.isChecked);
      localStorage.setItem("cust_checked", JSON.stringify(user));
    }
  };

  const moveTrash = async () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    const result = localStorage.getItem("customer_Delete");
    if (result === "All") {
      // Move all customers to trash
      const isDeleted = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customer/soft-delete`,
        {
          soft_delete: true,
          type: "All",
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
      );
      if (isDeleted.data.users.acknowledged === true) {
        toast.success(isDeleted.data.message);
        localStorage.setItem("customer_Delete", " ");
      } else {
        toast.error("Failed to Trashed");
      }
      navigate("/admin/customer_trash");
    } else if (result === "id") {
      // Move selected customers to trash
      const store = JSON.parse(localStorage.getItem("cust_checked"));
      try {
        await store.map((e) => {
          axios.put(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/customer/soft-delete`,
            {
              soft_delete: true,
              type: "id",
              id: e._id,
            },
            {
              headers: {
                Authorization: `Bearer ${getNewToken()}`,
              },
            }
          );
        });
        toast.success("Customer Move to Trash");
        navigate("/admin/customer_trash");
        localStorage.setItem("customer_Delete", " ");
      } catch (error) {
        toast.error("Failed to Delete customer account");
      }
    } else {
      toast.warning("Please Select Any Input");
    }
  };

  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const userStatus = (userIds) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
      .then((response) => {
        let users = response.data.user?.status
        if (users === false) {
          window.localStorage.removeItem('client');
          navigate('/admin');
          toast.warning("Your account has been deactivated");
        }
      }).catch((error) => console.error(`Error: ${error}`));
  }

  useEffect(() => {
    userStatus(userIds);
  }, [userIds])


  const handlePreview = () => {

    setPreviewModal(!previewModal);
  }

  const handleClosePreviewModal = () => {
    setPreviewModal(false);
    setshowCsvModal(false);
    setLoadingCsv(false);
    setFilename('');
  }

  const handleClearData =()=>{
    setFilename('')
  }


  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <h1 className="font20 mb-4 text-dark">Customers</h1>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={4} xxl={3} lg={4} md={12} className="mt-1">
                <div className="textGray font12">

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                      <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>

                </div>
              </Col>
              {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
              <Col xl={8} xxl={9} lg={8} md={12} className="mt-1" >
                <Row className={styles.break}>

                  <Col lg={5} className="mt-1 me-3 ps-3 ps-lg-0" style={{ width: "145px" }}>

                    <Button
                      variant="primary"
                      className="fullRounded px-4 py-2 fw-bold font12"
                      onClick={() => { setshowReduceModal(!showReduceModal); getbusinessPartners() }}
                      style={{ width: "150px" }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Modify funds</span>
                    </Button>
                  </Col>
                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{ width: "145px" }}>

                    <Button
                      variant="primary"
                      onClick={() => setshowCsvModal(!showCsvModal)}
                      className="fullRounded px-4 py-2 fw-bold font12"
                      style={{ width: "139px" }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Upload CSV</span>
                    </Button>
                  </Col>
                  <Col
                    lg={4}
                    className="mt-1 ps-3 ps-lg-0"
                    style={{ width: "156px" }}
                  >
                    <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      onClick={() => navigate("/admin/customers/add")}
                      style={{ width: "149.8px" }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Add Customer</span>
                    </Button>
                  </Col>
                  <Col
                    lg={4}
                    className="mt-1 ps-3 ps-lg-0"
                    style={{ width: "145px" }}
                  >
                    
                  </Col>

                  {/* for sort */}
                  <Col lg={4} xs={6} className="mt-1 ps-3 ms-md-1 ms-sm-0 ms-lg-0" >
                    <div className="custom-select">
                      <Select
                       styles={customStyles}
                        onChange={handleChange}
                        options={getbusinessPartnerss}
                        defaultValue={{
                          value: "Filter by:All",
                          label: "Filter by:All",
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* end sort */}
            </Row>
            {/* <Col className="mt-2" style={{ marginLeft: '10px' }}>
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col> */}
          </Container>
          <Col md={12} className="mt-4">
            <div className="table-scroll">
              <table
                className="table fontGilroy customer_table"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>
                    {/* <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th> */}
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                          {emailorder === "ASC" ? (
                            <FaSortDown onClick={() => emailsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => emailsorting("ASC")} />
                          )}
                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                        {partnerorder === "ASC" ? (
                          <FaSortDown onClick={() => partnersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => partnersorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                        {fundorder === "ASC" ? (
                          <FaSortDown onClick={() => fundsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => fundsorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-end">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Updated At</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Status</span>
                        {statusorder === "ASC" ? (
                          <FaSortDown onClick={() => statusSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => statusSorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th></th>
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
                  {isLoading ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin='auto'
                          color="#FF7020"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {noData ? <tr >
                        <td colSpan="8" className="text-center">
                          <h1 className="font16 text-dark" >No Customers</h1>
                        </td>
                      </tr>
                        :
                        customerData.map((cust) => (
                          <tr key={cust._id}>
                            <td className="font14">
                              <span>
                                <Link
                                  className="link"
                                  to={`/admin/customers/${cust._id}/edit`}
                                >
                                  {cust.first_name} {cust.last_name}
                                </Link>
                              </span>
                            </td>
                            {/* <td className="font14 fw-medium">
                          {cust.first_name} {cust.last_name}
                        </td> */}
                            <td className="font14 fw-medium">
                              <Link to={`/admin/customers/${cust._id}/edit`}>
                                {cust.email} {cust.id}
                              </Link>
                            </td>
                            <td className="font14 fw-medium">{cust.business_partner}</td>
                            <td className="font14 fw-medium">${Number(cust.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                            <td className="font14 fw-medium">
                                {cust.dateUpdate? 
                                cust.dateUpdate
                                : 
                                formatDate(cust.updatedAt).slice(0, 22).replace("T", "      ")
                                }
                            </td>
                            <td className="font14 fw-medium">
                              <Form.Check
                                type="switch"
                                label={cust.status ? "Active" : "Inactive"}
                                className="switchLabel"
                                checked={cust.status}
                                onChange={(e) => {
                                  handleStatus(cust._id, cust.status, cust.email, cust.business_partner_id);
                                }}
                              />
                            </td>
                            <td className="font14 fw-medium">
                              <div className="transactionLabel d-inline-flex align-items-center">
                                <div className="me-2">
                                  <img
                                    src="/assets/images/transcation.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <Link to={`/admin/transaction/${cust._id}`} >
                                    Transaction
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td >
                              {role === "Admin" ? (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-end"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => {
                                      navigate(`/admin/customers/${cust._id}/edit`);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => {
                                      handleDeleteUser(cust?._id);
                                        
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </DropdownButton>
                              ) : (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-end"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => {
                                      navigate(`/admin/customers/${cust._id}/edit`);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => {
                                      handleDeleteUser(cust?._id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </DropdownButton>
                              )}
                            </td>
                          </tr>
                        ))
                      }
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
            <Col className="mt-4" sm={12}>
              <Row>
          <Col md={3} className="">
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>

          {
            noData ? "" :
            <Col md={6} className=" mb-3 text-center">
                {
                  <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                    {pageNumber === 0 ? (
                      ""
                    ) : (

                      <li onClick={prevPage}>Previous </li>

                    )}

                    {items}


                    {numberOfPages - 1 === pageNumber ? (
                      ""
                    ) : (
                      <li onClick={nextPage}>Next</li>
                    )}
                    <li className="font14"><input style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => { pagenumberSearch(e.target.value) }}  ></input> <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages}</li>
                  </ul>
                }
              </Col>
          }
            </Row>
            </Col>
          {/* <Col className="col-4">{pageNumber +1}
          of
          {numberOfPages}
          </Col> */}
          {/* <Col>
          <input  style={{width:"30px"}} type="number" name="number" id="" onChange={(e)=> {setPageNumber(e.target.value)}} value={(pageNumber)} ></input>/ {numberOfPages}
          <div>{pageNumber}</div>
</Col> */}

        </Row>
      </Container>
      <Modal
        scrollable={true}
        show={previewModal}
        onHide={handleClosePreviewModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="font20 text-dark mb-0"
            id="contained-modal-title-vcenter"
          >
            CSV Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loadingCsv ? <Bars
              height="80"
              width="100%"
              margin='auto'
              color="#FF7020"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> :
          <>
            <div className="d-flex justify-content-between">
              {previewData1?.totalFunds !== null && (
                <h6>Total Funds : $ {previewData1?.totalFunds.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
              )}

              {previewData1?.registerCustomer !== null && (
                <h6>Register Customers : {previewData1?.registerCustomer}</h6>
              )}

            </div>
            <div className="table-scroll">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center">

                        <span className="me-1">Name</span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email

                        </span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Phone

                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Count</span>

                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody className="font12 fw-medium">



                  {

                      previewData?.map((cust,i) => (
                      <tr key={i}>
                        <td className="font14">

                          <span>
                            <Link
                              className="link"

                            >
                              {cust.first_name} {cust.last_name}
                            </Link>
                          </span>
                        </td>

                        <td className="font14 fw-medium">
                          {/* <div> */}
                          {cust.email} {cust.id}
                          {/* </Link> */}
                        </td>
                        <td className="font14 fw-medium">{cust.code} {cust.phone}</td>
                        <td className="font14 fw-medium">{cust.business_name}</td>
                        <td className="font14 fw-medium">${Number(cust.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                        <td className="font14 fw-medium">{cust.count}</td>
                        {/* <td className="font14 fw-medium">
                        {formatDate(cust.updatedAt).slice(0, 16).replace("T", "      ")}
                          </td> */}



                      </tr>
                    ))

                  }
                </tbody>
              </table>
            </div>
          </>
          }
        </Modal.Body>
        <Modal.Footer>

          {/* <Button
            variant="primary"
            className="fullRounded px-4 py-2 fw-bold font12"
            onClick={handleSubmit}
          >
            Confirm
          </Button> */}
          <Button
            variant="primary"
            className="fullRounded px-4 py-2 fw-bold font12"
            onClick={handleSubmit}
            disabled={loadingCsv} 
          >
            Confirm
          </Button>
          <Button
            variant="secondary"
            className="fullRounded px-4 py-2 fw-bold font12"
            onClick={() => handleClosePreviewModal()}
          >
            Close
          </Button>
        </Modal.Footer>

      </Modal>




      {/* <ToastContainer position="top-center" /> */}
      <Modal
        show={showCsvModal}
        onHide={handleClosehandleCsvModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
              File Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loadingCsv ? <Bars
              height="80"
              width="100%"
              margin='auto'
              color="#FF7020"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
              :
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>

                </div>
                <CustomerForm variant="primary" setCustomerData={setCustomers} setFilename={setFilename} filename={filename} />
              </>
            }
          </Modal.Body>
          <Modal.Footer>
           
            {filename.length > 0 ? <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              disabled
            >
              Upload
            </Button>
              :
           
              <Button
                variant="primary"
                type="submit"
                className="fullRounded px-4 py-2 fw-bold font12"
              >
                Upload
              </Button>
              
            }

            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => {
                handleClosePreviewModal();
                handleClearData(); 
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Reduce balance modal */}
      <Modal
        show={showReduceModal}
        onHide={handleCloseReduceModal}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reduce_modal_body">
            <div className="row g-3">
              <div className="col-12">
                <label htmlFor="business-partner" className="form-label">Business partner</label>
                <select name="" id="business-partner" className={`form-select ${selectedPartnerValidate == 'default' && startPartnerClick ? 'is-invalid' : ''}`} onChange={handlePartnerSelection} onClick={PartnerClick}>
                  <option value="default">Select Business partner</option>
                  {getbusinessPartnerss.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {selectedPartnerValidate === 'default' && startPartnerClick && (
                  <div className="invalid-feedback">Please select a valid Business partner.</div>
                )}
              </div>
              {selectedPartnerValidate !== 'default' &&
                <div className="col-12">
                  <label htmlFor="Customers-partner" className="form-label" >Customers</label>
                  <select name="" id="Customers-partner" className={`form-select ${selectedCustomerValidate === 'default' && startCustomerClick ? 'is-invalid' : ''}`} disabled={selectedPartnerValidate === 'default' ? true : false} onChange={handleCustomerSelection} onClick={CustomerClick} value={selectedCustomerValidate}>
                    <option value="default">Select Customers</option>
                    {getbusinessCustomer.map((option, index) => (
                      <option key={index} value={option._id}>
                        {option.first_name} {option?.last_name} - {option.email}
                      </option>
                    ))}
                  </select>
                  {selectedCustomerValidate === 'default' && selectedPartnerValidate !== 'default' && startCustomerClick && (
                    <div className="invalid-feedback">Please select a valid Customer.</div>
                  )}
                  {selectedPartnerValidate === 'default' && startPartnerClick && (
                    <div className="invalid-feedback">Please select a Business partner first.</div>
                  )}
                </div>
              }
              {selectedCustomerValidate !== 'default' && <>
                <div className="col-12">
                  <div className="my-2">
                    <label htmlFor="">Name: </label>
                    <span className="text-dark"> {customerName} {customerLastName}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Email: </label>
                    <span className="text-dark"> {customerEmail}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">phone Number: </label>
                    <span className="text-dark"> {customerPhone}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Balance: </label>
                    <span className="text-dark"> {`$${customerAmount == "NaN" ? '0' : Number(customerAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row g-2">
                    <div className="col-auto">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="fund_change"
                          id="fund_change1"
                          defaultChecked
                          onClick={reduceRadioChange}
                        />
                        <label className="form-check-label" htmlFor="fund_change1">
                          Reduce funds
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="fund_change"
                          id="fund_change2"
                          onClick={addRadioChange}
                        />
                        <label className="form-check-label" htmlFor="fund_change2">
                          Add funds
                        </label>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div className="form-group">
                        <input type="number" className={`form-control ${fundValue.length == 0 && startFundValidation && !fundValidate  || parseFloat(fundValue) > parseFloat(customerAmount) && startFundValidation && (reduces == true) ? 'is-invalid' : ''}`} value={fundValue} onChange={handleFundChange} onKeyDown={handleKeyDown} placeholder="Enter amount" style={{ maxWidth: "100%", }} />
                        {startFundValidation && <>
                          {fundValue.length > 0 && (
                            <div className="valid-feedback">Looks good!</div>
                          )}

                          {parseFloat(fundValue) > parseFloat(customerAmount) && (reduces == true) && (
                            <div className="invalid-feedback">Amount is more than Available Balance</div>
                          )}
                           {fundValue.length === 0 && !fundValidate &&
                           (
                            <div className="invalid-feedback">Please enter an amount.</div>
                          )}
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseReduceModal}>
            Close
          </Button>
          <Button variant="primary" disabled={isSubmitting} className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" onClick={doubleConfirmation}>{isSubmitting ? 'Update' : 'Update'}</Button>
        </Modal.Footer>
      </Modal>

      





      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="md" centered>
        <Modal.Body>
        <Col>
        <img className="modal-img"
            src="/assets/images/exclamation.svg"
            // className="img-fluid"
            alt=""
        />
        <br />
        <br />
        <h3 className="modal-text">Are you sure, you want to Delete?</h3>
        <br />
        <Row className="modal-row">
            <Col md ={1}></Col>
            <Col md={4}>
            <Button
              className="btn btn-danger danger-btn"
              onClick={() => {
                handleConfirmDelete(); 
              }}
            >
                    
                    <span><b>Delete</b></span>
                </Button>
            </Col>
            <Col className="middle-col" md ={2}></Col>
            <Col md={4}>
            <Button className="btn btn-success success-btn" onClick={handleCloseModal}>
                    <span><b>Cancel</b></span>
                </Button>
             </Col>
            <Col md ={1}></Col>
        </Row>
        <br />
        </Col>
        </Modal.Body>
  </Modal> 

  <Modal show={showConfirmModal} 
      onHide={handleCloseConfirmModal} 
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      >
        <Modal.Header className='bg-warning'closeButton>
          <Modal.Title>Do you want to modify funds?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Business Partner :<span style={{marginLeft:"10px"}}>{busName}</span></p>
          <p>Customer Name :<span style={{marginLeft:"10px"}}>{cusName}</span></p>
          <p>Customer Email :<span style={{marginLeft:"10px"}}>{cusEmail}</span></p>
          <p>Current Funds :<span style={{marginLeft:"10px"}}>{`$${Number(currentFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
          {checkFundStatus ?
          <p>Reduced Funds :<span style={{marginLeft:"10px"}}>{`$${Number(reducedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
          :
          <p>Added Funds : <span style={{marginLeft:"10px"}}> {`$${Number(addedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>}
          <p>New Funds :<span style={{marginLeft:"10px"}}>{`$${Number(newFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary"className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseConfirmModal}>
            Decline
          </Button>
          <Button variant="primary" className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" onClick={balanceSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Customers;
